@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Montserrat", sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  margin-bottom: 20px;
  background-color: rgb(22, 21, 21);
}

.logo > .link > h2 {
  color: var(--theame-color);
  cursor: pointer;
}

.right {
  display: flex;
  align-items: center;
}

.right > .link > h6 {
  color: var(--font-color);
  cursor: pointer;
  padding: 30px 10px;
  margin: 0;
  font-size: 15px;
}

.right > .link > h6:hover {
  color: var(--theame-color);
  transition: color 0.3s ease-out;
}

.right > .link > .cart {
  cursor: pointer;
  padding: 31px 10px;
  margin: 0;
  font-size: 15px;
}

.right > .link > .cart:hover {
  color: var(--theame-color);
  transition: color 0.3s ease-out;
}

.dropdown-toggle {
  color: whitesmoke;
  text-decoration: none;
  padding: 30px 10px;
  font-size: 14px;
}

.dropdown-toggle:hover {
  color: var(--theame-color);
  transition: color 0.3s ease-out;
}

.dropdown-menu {
  background-color: rgb(22, 21, 21);
}

.show > .dropdown-toggle {
  color: var(--theame-color);
}

.show > .dropdown-toggle:hover {
  color: whitesmoke;
}

.dropdown-menu > .dropdown-item {
  color: whitesmoke;
  font-size: 20px;
  font-weight: lighter;
  border-bottom: 0.1px solid gray;
}

.dropdown-menu > .dropdown-item:hover {
  color: black;
}

@media screen and (max-width: 450px) {
  .navbar {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .logo {
    padding-top: 20px;
  }
}

.pizza {
  background-color: rgb(22, 21, 21);
  border-radius: 10px;
  height: 500px;
  width: 300px;
  margin: 30px 0;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding-bottom: 500px !important;
}

.pizza > h4 {
  color: var(--font-color);
  height: 50px;
  text-transform: uppercase;
  padding: 7px 0 12px 0;
  margin-bottom: 10px;
}

.pizza__image {
  min-height: 280px;
  width: 300px;
  cursor: pointer;
}

.pizza__descrip__top {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 10px;
}

.varients > p {
  font-size: 16px;
  color: grey;
}

.quantity > p {
  font-size: 16px;
  color: grey;
}

.varients > select {
  width: 110px;
  outline: none;
  border: none;
  margin-top: 5px;
  font-size: 15px;
  margin-left: 0 10px;
  background-color: black;
  padding: 10px;
  font-weight: 200;
  cursor: pointer;
}

.quantity > select {
  width: 110px;
  outline: none;
  border: none;
  margin-top: 5px;
  font-size: 15px;
  margin-left: 0 10px;
  background-color: black;
  padding: 10px;
  font-weight: 200;
  cursor: pointer;
}

.pizza__descrip__bottom {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 10px 0;
}

.pizza__descrip__bottom > p {
  color: grey;
}

.pizza__descrip__bottom > button {
  color: var(--theame-color);
  background-color: transparent;
  background-color: initial;
  border: 2px solid var(--theame-color);
  padding: 10px;
  outline: none;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  border-radius: 200px;
}

.pizza__descrip__bottom > button:hover {
  color: whitesmoke;
  background-color: var(--theame-color);
  transition: background-color 0.5s ease-out;
  transition: color 0.2s ease-in;
}
.pizza__modal {
  color: rgb(22, 21, 21);
  text-align: center;
}

.modal__title {
  color: black;
  margin: 0 auto;
}

.modal__body > img {
  max-width: 500px;
  width: 100%;
  max-height: 300px;
  height: 50%;
  object-fit: contain;
}

.modal__body > p {
  margin-top: 25px;
  color: black;
}

.modal__button {
  color: whitesmoke;
  outline: none;
  border: none;
  border-radius: 30px;
  background-color: var(--theame-color);
  padding: 10px;
  width: 50%;
  margin: 0 auto;
}

.loading {
  margin: auto;
  padding-top: 10%;
}

.spinner-border {
  padding: 50px;
}

.alert {
  font-size: 10px;
  align-items: center;
  border-radius: 10px 10px 0 0;
}

.alert > p {
  text-align: center;
  padding: 0;
  margin: 0;
  color: inherit;
}

.filter {
  display: flex;
  width: 80%;
  margin: 0 auto;
  background-color: rgb(21, 20, 20);
  border-radius: 10px;
}

.filter__inner {
  margin: 0 auto;
  padding: 10px;
}

.filter__inner > input {
  padding: 10px;
  background-color: black;
  margin-right: 20px;
  border-radius: 10px;
  border: none;
  outline: none;
}

.filter__inner > select {
  background-color: black;
  padding: 10px;
  border-radius: 10px;
  outline: none;
  border: none;
  margin-right: 20px;
  cursor: pointer;
}

.filter__inner > select > option {
  color: whitesmoke;
  font-size: 20px;
  margin-top: 10px;
}

.filter__inner > button {
  border: 2px solid var(--theame-color);
  color: var(--theame-color);
  background-color: inherit;
  padding: 8px;
  cursor: pointer;
}

.filter__inner > button:hover {
  border: 1px solid var(--theame-color);
  background-color: var(--theame-color);
  transition: background-color 0.9s ease-out;
  color: whitesmoke;
}

@media screen and (max-width: 700px) {
  .filter {
    width: 70%;
    margin: 0 10%;
  }

  .filter__inner {
    text-align: center;
    display: flex;
    flex-direction: column;
  }
  .filter__inner > select {
    margin-top: 10px;
  }
  .filter__inner > button {
    margin-top: 10px;
    width: 92%;
  }
}

.pizzas {
  display: flex;
  flex-wrap: wrap;
  margin: 0 10%;
  padding: 0;
  justify-content: space-between;
}

@media screen and (max-width: 700px) {
  .pizzas {
    margin: 0 0 0 10%;
  }
}

.btn {
  color: white;
  background-color: var(--theame-color);
  padding: 10px;
  border: none;
  outline: none;
  border-radius: 20px;
}

#modal-body {
  color: black;
}

.alert {
  font-size: 10px;
  align-items: center;
  border-radius: 10px 10px 0 0;
}

.alert > p {
  text-align: center;
  padding: 0;
  margin: 0;
  color: inherit;
}

.cart {
  display: flex;
  justify-content: space-around;
}

.cart__shoppingCart {
  display: flex;
  flex-direction: column;
  flex: 0.4 1;
}

.cart__shoppingCart > h4 {
  border-bottom: 1px solid rgb(22, 21, 21);
  padding-bottom: 20px;
  padding-top: 20px;
}

.cart__items {
  border-bottom: 1px solid rgb(22, 21, 21);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cart__item__info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 10px;
  margin-left: 20px;
}

.cart__item__info > p {
  font-size: 12px;
  color: grey;
  text-align: left;
}

.operations {
  cursor: pointer;
  color: greenyellow;
  padding: 5px 10px;
  margin: 3px;
  font-size: 15px;
  text-align: center;
  border: 1px solid greenyellow;
  border-radius: 100%;
}

.operations:hover {
  color: black;
  background-color: yellowgreen;
  transition: background-color 0.5s ease-out;
}

.cart__items > .cart__items__right > img {
  height: 100px;
  width: 100px;
  border-radius: 100%;
}

.cart__items__right {
  display: flex;
  align-items: center;
}

.cart__items__right > p {
  cursor: pointer;
  margin-left: 20px;
  margin-right: 10px;
  font-size: large;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 100%;
  border: 1px solid red;
  color: red;
}

.cart__items__right > p:hover {
  color: whitesmoke;
  background-color: red;
  transition: background-color 0.5s ease-out;
}

.cart__subtotal {
  flex: 0.2 1;
}

.cart__subtotal > h4 {
  padding-top: 20px;
  padding-bottom: 20px;
}
.cart__subtotal > button {
  color: white;
  background-color: var(--theame-color);
  padding: 10px;
  border: none;
  outline: none;
  border-radius: 20px;
}

@media screen and (max-width: 800px) {
  .cart__items > .cart__items__right > img {
    display: none;
  }
  .cart__items__right > p {
    font-size: small;
    padding-left: 5px;
    padding-right: 5px;
  }
  .operations {
    padding: 0 3px;
    border: none;
  }
}

.login_page {
  margin-top: 2%;
  font-weight: lighter !important;
}

.login {
  border-radius: 20px;
  background-color: rgb(21, 20, 20);
  margin: auto;
  width: 35%;
  text-align: left;
}

.login > h3 {
  padding: 20px 0 0 28px;
  font-weight: 400;
  color: var(--font-color);
}

.login__form {
  display: flex;
  flex-direction: column;
  padding: 10px 0 0 10px;
}

.login__form > input {
  width: 85%;
  margin-left: 20px;
  padding: 10px;
  padding-left: 0;
  outline: none;
  border: none;
  color: lightgray;
  background-color: inherit;
  border-bottom: 1px solid grey;
}

.login__form > input:focus {
  border-bottom: 1px solid var(--theame-color);
}

.login__form > button {
  width: 85%;
  margin: 20px 0 20px 20px;
  padding: 10px;
  text-align: center;
  color: whitesmoke;
  background-color: var(--theame-color);
  outline: none;
  border: none;
  border-radius: 10px;
}

.register__user {
  padding: 20px;
  padding-top: 0;
  margin-left: 15px;
  font-size: 14px;
  color: gray;
  display: flex;
  flex-direction: column;
}

.register__user__signUp {
  padding: 10px;
  text-decoration: none;
}

.register__user__signUp:hover {
  color: var(--theame-color);
}

@media screen and (max-width: 800px) {
  .login {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  .login__form {
    padding: 10px 10px 0 10px;
  }
}

.register_page {
  margin-top: 2%;
}

.register {
  border-radius: 20px;
  background-color: rgb(21, 20, 20);
  margin: auto;
  width: 35%;
  text-align: left;
}

.register > h3 {
  padding: 20px 0 0 28px;
  color: var(--font-color);
}

.register__form {
  display: flex;
  flex-direction: column;
  padding: 10px 0 0 10px;
}

.register__form > input {
  width: 85%;
  margin-left: 20px;
  padding: 10px;
  padding-left: 0;
  outline: none;
  border: none;
  color: lightgray;
  background-color: inherit;
  border-bottom: 1px solid grey;
}

.register__form > input:focus {
  border-bottom: 1px solid var(--theame-color);
}

.register__form > button {
  width: 85%;
  margin: 20px 0 20px 20px;
  padding: 10px;
  text-align: center;
  color: whitesmoke;
  background-color: var(--theame-color);
  outline: none;
  border: none;
  border-radius: 10px;
}

.login__user {
  padding: 20px;
  padding-top: 0;
  margin-left: 15px;
  font-size: 14px;
  color: gray;
}

.login__user__login {
  padding: 10px;
  text-decoration: none;
}

.login__user__login:hover {
  color: var(--theame-color);
}

@media screen and (max-width: 800px) {
  .register {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  .register__form {
    padding: 10px 10px 0 10px;
  }
}

.order {
  display: flex;
  justify-content: space-between;
  background-color: rgb(21, 20, 20);
  margin-bottom: 20px;
  text-align: left;
  padding: 15px;
}

.order > .left > h6 {
  font-size: 20px;
  padding: 5px;
  font-family: sans-serif;
}

.order > .center > h6 {
  font-size: 20px;
  padding: 5px;
  font-family: sans-serif;
}

.order > .right > h6 {
  font-size: 20px;
  padding: 5px;
  font-family: sans-serif;
}

.order > .left > p {
  font-size: 11px;
  color: gray;
  font-family: sans-serif;
  padding-right: 7px;
}

.order > .center > p {
  font-size: 11px;
  color: gray;
  font-family: sans-serif;
  padding-right: 7px;
}

.order > .right > p {
  font-size: 11px;
  color: gray;
  font-family: sans-serif;
  padding-right: 7px;
}

.order > .left {
  display: flex;
  flex-direction: column;
  flex: 0.37 1;
}

.order > .center {
  flex: 0.3 1;
}

.order > .right {
  display: flex;
  flex-direction: column;
  flex: 0.33 1;
  align-items: flex-start;
}

.orderScreen {
  max-width: 85%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.orderScreen > h4 {
  border-bottom: 0.1px dotted var(--theame-color);
  padding-bottom: 20px;
  padding-top: 20px;
  margin-bottom: 20px;
}

.orders > hr {
  color: var(--theame-color);
}

.ordersList > h5 {
  padding: 20px;
}

.table-responsive::-webkit-scrollbar {
  display: none;
}

.table {
  background-color: rgb(21, 20, 20);
  border: 1px solid gray;
}

thead > tr > th {
  color: var(--theame-color);
}

td,
th,
tr {
  text-align: left;
  font-size: 14px;
}

td,
tr {
  font-size: 10px;
  color: lightgrey;
}

td > button {
  color: white;
  background-color: red;
  outline: none;
  border: none;
  margin: 0;
  text-align: center;
  padding-right: 2px;
  margin-left: 10px;
}

td > h6 {
  font-size: 10px;
  color: greenyellow;
  padding-top: 2px;
  margin-bottom: 0px;
  padding-left: 10px;
}

td > button:hover {
  color: red;
  background-color: inherit;
  transition: background-color 0.9s ease-in-out;
}

.pizzasList > h5 {
  padding: 20px;
}

.table-responsive::-webkit-scrollbar {
  display: none;
}

.table {
  background-color: rgb(21, 20, 20);
  border: 1px solid gray;
}

thead > tr > th {
  color: var(--theame-color);
}

td,
th,
tr {
  text-align: left;
  font-size: 14px;
}

td,
tr {
  font-size: 10px;
  color: lightgrey;
}

.bi-trash {
  color: red;
  cursor: pointer;
  padding: 3px 4px;
  border-radius: 100%;
  border: 1px solid red;
  margin: 5px;
}

.bi-trash:hover {
  color: whitesmoke;
  background-color: red;
}

.bi-pencil-square {
  margin: 5px;
  font-size: 14px;
  cursor: pointer;
  color: yellowgreen;
}

.bi-pencil-square:hover {
  border-bottom: 1px solid yellowgreen;
}

.addNewPizza {
  margin-top: 2%;
}

.addNewPizza__inner {
  border-radius: 20px;
  background-color: rgb(21, 20, 20);
  margin: 20px auto;
  width: 35%;
  text-align: left;
}

.addNewPizza__inner > h5 {
  padding: 20px 0 0 30px;
}

.addNewPizza__form {
  display: flex;
  flex-direction: column;
  padding: 10px 0 0 10px;
}

.addNewPizza__form > input {
  width: 85%;
  margin-left: 20px;
  padding: 10px;
  padding-left: 0;
  font-weight: 2px;
  outline: none;
  border: none;
  color: lightgray;
  background-color: transparent;
  background-color: initial;
  border-bottom: 1px solid grey;
}

.addNewPizza__form > input:focus {
  border-bottom: 1px solid var(--theame-color);
}

.addNewPizza__form > .addNewPizza__form__link > button {
  width: 85%;
  margin: 20px 0 20px 20px;
  padding: 10px;
  text-align: center;
  color: whitesmoke;
  background-color: var(--theame-color);
  outline: none;
  border: none;
  border-radius: 10px;
}

@media screen and (max-width: 800px) {
  .addNewPizza__inner {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  .addNewPizza__form {
    padding: 10px 10px 0 10px;
  }
}

.addNewPizza__form > p {
  margin: 0;
  padding: 10px 10px 5px 22px;
  font-size: 13px;
  color: grey;
}

.usersList > h5 {
  padding: 20px;
}

.table-responsive::-webkit-scrollbar {
  display: none;
}
.usersList > .table {
  width: 20px !important;
  overflow-y: scroll;
  overflow-x: scroll;
  background-color: blue;
}

.admin {
  max-width: 80%;
  margin: 0 auto;
}

.admin > h4 {
  border-bottom: 1px solid var(--theame-color);
  padding: 10px;
  margin-bottom: 20px;
}

.admin > .admin__nav > .admin__nav__inner {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(21, 20, 20);
}

.admin__nav > .admin__nav__inner > .admin__nav__inner__link > p {
  padding: 12px;
  margin: 0;
}

.admin__nav > .admin__nav__inner > .admin__nav__inner__link > p:hover {
  background-color: var(--theame-color);
  transition: background-color 0.9s ease-out;
}

.admin__nav > .admin__nav__routes {
  display: flex;
  flex-direction: column;
}

* {
  --theame-color: rgb(245, 148, 83);
  --font-color: rgb(199, 196, 196);
  color: whitesmoke;
}

body {
  background-color: black;
  text-align: center;
}

