.pizza {
  background-color: rgb(22, 21, 21);
  border-radius: 10px;
  height: 500px;
  width: 300px;
  margin: 30px 0;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding-bottom: 500px !important;
}

.pizza > h4 {
  color: var(--font-color);
  height: 50px;
  text-transform: uppercase;
  padding: 7px 0 12px 0;
  margin-bottom: 10px;
}

.pizza__image {
  min-height: 280px;
  width: 300px;
  cursor: pointer;
}

.pizza__descrip__top {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 10px;
}

.varients > p {
  font-size: 16px;
  color: grey;
}

.quantity > p {
  font-size: 16px;
  color: grey;
}

.varients > select {
  width: 110px;
  outline: none;
  border: none;
  margin-top: 5px;
  font-size: 15px;
  margin-left: 0 10px;
  background-color: black;
  padding: 10px;
  font-weight: 200;
  cursor: pointer;
}

.quantity > select {
  width: 110px;
  outline: none;
  border: none;
  margin-top: 5px;
  font-size: 15px;
  margin-left: 0 10px;
  background-color: black;
  padding: 10px;
  font-weight: 200;
  cursor: pointer;
}

.pizza__descrip__bottom {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 10px 0;
}

.pizza__descrip__bottom > p {
  color: grey;
}

.pizza__descrip__bottom > button {
  color: var(--theame-color);
  background-color: initial;
  border: 2px solid var(--theame-color);
  padding: 10px;
  outline: none;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  border-radius: 200px;
}

.pizza__descrip__bottom > button:hover {
  color: whitesmoke;
  background-color: var(--theame-color);
  transition: background-color 0.5s ease-out;
  transition: color 0.2s ease-in;
}
.pizza__modal {
  color: rgb(22, 21, 21);
  text-align: center;
}

.modal__title {
  color: black;
  margin: 0 auto;
}

.modal__body > img {
  max-width: 500px;
  width: 100%;
  max-height: 300px;
  height: 50%;
  object-fit: contain;
}

.modal__body > p {
  margin-top: 25px;
  color: black;
}

.modal__button {
  color: whitesmoke;
  outline: none;
  border: none;
  border-radius: 30px;
  background-color: var(--theame-color);
  padding: 10px;
  width: 50%;
  margin: 0 auto;
}
