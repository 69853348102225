.cart {
  display: flex;
  justify-content: space-around;
}

.cart__shoppingCart {
  display: flex;
  flex-direction: column;
  flex: 0.4;
}

.cart__shoppingCart > h4 {
  border-bottom: 1px solid rgb(22, 21, 21);
  padding-bottom: 20px;
  padding-top: 20px;
}

.cart__items {
  border-bottom: 1px solid rgb(22, 21, 21);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cart__item__info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 10px;
  margin-left: 20px;
}

.cart__item__info > p {
  font-size: 12px;
  color: grey;
  text-align: left;
}

.operations {
  cursor: pointer;
  color: greenyellow;
  padding: 5px 10px;
  margin: 3px;
  font-size: 15px;
  text-align: center;
  border: 1px solid greenyellow;
  border-radius: 100%;
}

.operations:hover {
  color: black;
  background-color: yellowgreen;
  transition: background-color 0.5s ease-out;
}

.cart__items > .cart__items__right > img {
  height: 100px;
  width: 100px;
  border-radius: 100%;
}

.cart__items__right {
  display: flex;
  align-items: center;
}

.cart__items__right > p {
  cursor: pointer;
  margin-left: 20px;
  margin-right: 10px;
  font-size: large;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 100%;
  border: 1px solid red;
  color: red;
}

.cart__items__right > p:hover {
  color: whitesmoke;
  background-color: red;
  transition: background-color 0.5s ease-out;
}

.cart__subtotal {
  flex: 0.2;
}

.cart__subtotal > h4 {
  padding-top: 20px;
  padding-bottom: 20px;
}
.cart__subtotal > button {
  color: white;
  background-color: var(--theame-color);
  padding: 10px;
  border: none;
  outline: none;
  border-radius: 20px;
}

@media screen and (max-width: 800px) {
  .cart__items > .cart__items__right > img {
    display: none;
  }
  .cart__items__right > p {
    font-size: small;
    padding-left: 5px;
    padding-right: 5px;
  }
  .operations {
    padding: 0 3px;
    border: none;
  }
}
