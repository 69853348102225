* {
  --theame-color: rgb(245, 148, 83);
  --font-color: rgb(199, 196, 196);
  color: whitesmoke;
}

body {
  background-color: black;
  text-align: center;
}
