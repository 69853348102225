.pizzas {
  display: flex;
  flex-wrap: wrap;
  margin: 0 10%;
  padding: 0;
  justify-content: space-between;
}

@media screen and (max-width: 700px) {
  .pizzas {
    margin: 0 0 0 10%;
  }
}
