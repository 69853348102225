.alert {
  font-size: 10px;
  align-items: center;
  border-radius: 10px 10px 0 0;
}

.alert > p {
  text-align: center;
  padding: 0;
  margin: 0;
  color: inherit;
}
